import React from 'react';
import { Helmet } from 'react-helmet-async';

import { usePagesApi } from './api/usePagesApi';
import { useProductsApi } from './api/useProductsApi';
import { useAppContext } from './AppContext';
import { env } from './env';
import { t } from './i18n/i18n';
import { useHref } from './router/router';
import { getAbsoluteUrl, getDefaultLocale } from './util';

export interface MainHelmetProps {
  faviconIco?: string;
  faviconPng?: string;
}
export const MainHelmet = ({ faviconIco, faviconPng }: MainHelmetProps) => {
  const [title, setTitle] = React.useState<string>(t('misc.storeName'));
  const [description, setDescription] = React.useState<string>();
  const [pathsInAllLocales, setPathsInAllLocales] = React.useState<Partial<Record<string, string>>>();

  const { getPageMetaByPath } = usePagesApi();
  const { hasMultipleLocalesOrShippingCountries, info, locale, routeInfo } = useAppContext();
  const { getPathsInAllLocalesByPath } = usePagesApi();
  const { getUrlsInAllLocalesByPath } = useProductsApi();

  /*
    Al cambio della path chiedo i meta dati
  */
  React.useEffect(() => {
    routeInfo?.location.pathname &&
      routeInfo.dynamicSegment === false &&
      getPageMetaByPath(routeInfo.location.pathname).then((response) => {
        setTitle(response.data?.title ?? t('misc.storeName'));
        setDescription(response.data?.description);
      });
  }, [getPageMetaByPath, routeInfo?.dynamicSegment, routeInfo?.location.pathname]);

  /*
    Al cambio della path chiedo le url nelle altre linge, per l'hreflang
  */
  React.useEffect(() => {
    if (hasMultipleLocalesOrShippingCountries) {
      if (routeInfo?.location.pathname && routeInfo.dynamicSegment === false) {
        if (routeInfo.type === 'product') {
          getUrlsInAllLocalesByPath(routeInfo.location.pathname, { suppressErrorModal: true })
            .then((response) => {
              setPathsInAllLocales(response.data);
            })
            .catch(() => {
              // pazienza
            });
        } else if (
          routeInfo.type === 'catalog' ||
          routeInfo.type === 'code' ||
          routeInfo.type === 'template' ||
          routeInfo.type === 'route'
        ) {
          getPathsInAllLocalesByPath(routeInfo.location.pathname, { suppressErrorModal: true })
            .then((response) => {
              setPathsInAllLocales(response.data);
            })
            .catch(() => {
              // pazienza
            });
        } else if (routeInfo.type === 'fixed') {
          setPathsInAllLocales(
            info?.supportedLocales
              ? info.supportedLocales.reduce<Record<string, string>>((prev, curr) => {
                  prev[curr.code] = routeInfo.location.pathname;
                  return prev;
                }, {})
              : {}
          );
        }
      }
    }
  }, [
    getPathsInAllLocalesByPath,
    getUrlsInAllLocalesByPath,
    info?.supportedLocales,
    routeInfo?.dynamicSegment,
    routeInfo?.location.pathname,
    routeInfo?.type,
    hasMultipleLocalesOrShippingCountries,
  ]);

  const canonicalHref = useHref(routeInfo?.location.pathname ?? '');
  const isPaginatedSearch: boolean = routeInfo?.location.search.includes('pageNum') ?? false;
  const hasIdStoreSku: boolean = routeInfo?.location.search.includes('idStoreSku') ?? false;

  return (
    <>
      {locale && (
        <Helmet>
          <title>{title}</title>
          {description && <meta content={description} name="description" />}
          {faviconIco && (
            <link href={faviconIco.startsWith('http') ? faviconIco : `${env.PUBLIC_URL}/${faviconIco}`} rel="icon" />
          )}
          {faviconPng && (
            <link
              href={faviconPng.startsWith('http') ? faviconPng : `${env.PUBLIC_URL}/${faviconPng}`}
              rel="apple-touch-icon"
            />
          )}
          {info?.shippingCountriesLocales &&
            Object.entries(info.shippingCountriesLocales).map(([_shippingCountry, shippingCountryLocales]) =>
              shippingCountryLocales?.map(
                (shippingCountryLocale) =>
                  pathsInAllLocales && (
                    <link
                      href={getAbsoluteUrl(
                        `/${shippingCountryLocale.code}-${_shippingCountry.toLowerCase()}${
                          pathsInAllLocales[shippingCountryLocale.code] ?? ''
                        }`
                      )}
                      hrefLang={`${shippingCountryLocale.code}-${_shippingCountry.toLowerCase()}`}
                      key={`${shippingCountryLocale.code}-${_shippingCountry.toLowerCase()}`}
                      rel="alternate"
                    />
                  )
              )
            )}

          {pathsInAllLocales && info && (
            <link
              href={getAbsoluteUrl(
                `/${getDefaultLocale(info).code}-${info.defaultShippingCountry?.code.toLowerCase()}${
                  pathsInAllLocales[getDefaultLocale(info).code] ?? ''
                }`
              )}
              hrefLang="x-default"
              rel="alternate"
            />
          )}
          {!isPaginatedSearch && !hasIdStoreSku && <link href={getAbsoluteUrl(canonicalHref)} rel="canonical" />}
          {!isPaginatedSearch && routeInfo?.location.search !== '' && !hasIdStoreSku && (
            <meta content="noindex" name="robots"></meta>
          )}
        </Helmet>
      )}
    </>
  );
};
