import { CustomerBusinessApiType, CustomerProfileApiType } from '@b2x/storefront-api-js-client/src';

export const en = {
  accessibility: {
    button: {
      change_password: 'Proceed to change password',
      more_info: 'Discover more info',
      swiper_next: 'Swiper next',
      swiper_prev: 'Swiper previous',
    },
    consents: {
      newsletter: 'I agree to the newsletter',
    },
    footer: {
      socialIcon: 'Visit our {{socialName}} social channel',
    },
    icons: {
      account: 'Go to your personal area',
      account_login: 'Go to login or registration',
      cart: 'Go to shopping cart',
      cart_not_empty: 'You have {{items}} in the cart, go',
      search: 'Website search',
      wishlist: 'Go to your wishlist', 
      wishlist_not_empty: 'You have {{items}} in the wishlist, go',
    },
    logo: {
      goToHome: '{{brandName}} - Go to homepage',
    },
    productTile: {
      gift_card: 'Select gift card',
      productName: 'Go to {{productName}}',
      wishlist: 'Add {{productName}} to your wishlist',
    },
    progressbar: 'This product has a score of {{currentValue}}',
    select: {
      quantity: 'Select quantity for {{productName}}',
      review_filter: 'Filter for number of stars',
    }, 
  },
  account: {
    coupons: {
      active: 'Active',
      code: 'Code',
      description: 'Description',
      empty: 'No discount voucher',
      expired: 'Expired',
      expiryDate: 'Expiry date',
      numberOfUses: 'Number of uses',
      status: 'Status',
    },
    create: 'Create your account',
    creditCards: {
      addCreditcard: 'Add your credit/debit card',
      noCardRegistered: 'No registered credit/debit card',
    },
    edit: 'Edit',
    emptyWishlist: 'No products added to the wishlist',
    info: {
      additionalInformation: {
        children: {
          birthdate: 'Birthdate',
          name: 'Name',
          sex: 'Sex',
          surname: 'Surname',
          title: 'Children',
        },
        document: {
          authorityDescription: 'Authority description',
          authorityType: 'Authority type',
          districtName: 'District name',
          districtType: 'District type',
          documentNumber: 'Document number',
          documentType: 'Document type',
          releaseDate: 'Release date',
          title: 'Document',
        },
        other: 'Other',
        title: 'Additional information',
      },
      birthdate: 'Birth date',
      businessType: {
        label: 'Business type',
        options: {
          COMPANY: 'Company',
          PROPRIETORSHIP: 'Individual company/Freelancer',
        } as Record<CustomerBusinessApiType, string>,
      },
      company: 'Company',
      editPassword: 'Edit password',
      email: 'Email',
      loginData: 'Log in data',
      name: 'Name',
      pec: 'Pec',
      personalAddresses: 'Address',
      personalData: 'Personal data',
      phone: 'Mobile phone',
      profile: {
        label: 'Profile',
        options: {
          BUSINESS: 'Business',
          PRIVATE: 'Private',
        } as Record<CustomerProfileApiType, string>,
      },
      secondaryPhone: 'Secondary phone',
      sex: {
        options: {
          F: 'Female',
          M: 'Male',
          O: 'Not specified',
        },
        title: 'Gender',
      },
      surname: 'Surname',
      taxCode: 'Tax code',
      title: 'Invoice data',
      uniqueCode: 'Recipient code (e-invoice)',
      vatNumber: 'Vat number',
    },
    insertCredentials: 'If registered, enter your email and password',
    login: 'Log in',
    logout: 'Log out',
    loyalty: {
      pointsBalance: 'your points balance',
      pointsTable: {
        awardsLabel: 'Awards',
        couponLabel: 'Discount code',
        footerText: 'Use the discount code when purchasing',
        pointsLabel: 'Points',
        ptLabel: 'pt',
      },
      shopping: 'start shopping',
    },
    newCustomer: 'New customer?',
    orders: {
      details: {
        closeOrderDetails: 'Close',
        code: 'Code',
        color: 'Color',
        date: 'Date',
        invoiceDownload: 'Invoice download',
        invoiceNotReady: 'Invoice not ready yet',
        paymentAddress: 'Payment address',
        price: 'Price',
        product: 'Product',
        quantity: 'Quantity',
        receiverShipping: 'Receiver',
        review: 'Review',
        shippingAddress: 'Shipping address',
        showOrderDetails: 'Show order details',
        size: 'Size',
        status: 'Status',
        total: 'Total',
        totalAmount: 'Total amount',
        totalProducts: 'Total products',
        totalShipping: 'Total shipping',
        trackingCode: 'Tracking code',
        unitPrice: 'Price per unit',
      },
      noOrders: 'No order found',
      status: {
        arrived: 'Arrived',
        cancelled: 'Cancelled',
        preparing: 'Preparing',
        readyToShipping: 'Ready to shipping',
        shipping: 'Shipping',
        waitPayment: 'Waiting payment',
        waitingAcceptance: 'Waiting acceptance',
      },
      trackOrdersState: 'Track the status of your orders',
    },
    passwordForgotten: 'Forgotten password?',
    quickReorder: {
      noProducts: 'no previously purchased products',
      productCount: '',
      productCount_one: '{{count}} product',
      productCount_other: '{{count}} products',
      table: {
        product: 'Product',
        quantity: 'Quantity'
      }
    },
    register: 'Register',
    sectionDisabled: 'Section disabled',
    taxBenefits: {
      alerts: {
        danger: 'Documents have not been approved',
        success: 'User enabled',
        warning: 'Documents are in the approval process',
      },
    },
    userRegistered: 'Registered customer?',
    welcomeMessage: 'Hello {{name}}, find out your personal area',
    wishlist: 'Move to wishlist',
  },
  alert: {
    CART_DIFFERENT_SKU_PRICE: '',
    CART_INVOICE_MANDATORY_FLAG: 'Request invoice to finalise order',
    CART_MINIMUM_COST: 'Minimum cart amount not reached',
    CART_MIN_CAR_SELLER_VIOLATED: '',
    CART_REGION_NOT_REACHABLE_EXTRA_FRESH: '',
    CART_SKU_NOT_SHIPPABLE_IN_COUNTRY_VIOLATED: 'This product is not sellable in the selected Country',
    GUEST_CHECKOUT_NOT_ALLOWED: '',
    INVOICE_MANDATORY_MISSING_DATA: 'An invoice has been requested but billing information is missing',
    MISSING_BILLING_ADDRESS: 'Missing billing address',
    MISSING_EMAIL: 'Missing email',
    MISSING_PAYMENT_METHOD: 'Missing paymet method',
    MISSING_SHIPPING_ADDRESS: 'Missing shipping address',
    MISSING_SHIPPING_PROFILE: 'Missing shipping profile',
    UNSOLICITED_INVOICE: 'Please note, no invoice has been requested for this order',
    WAREHOUSEITEM_NOT_IN_STOCK: '',
    WAREHOUSESKU_NOT_AVAILABLE: 'Product not available for requested quantity. Available stocks: {{maxQty}}', // da revisionare
  },
  api: {
    error: {
      AddingSkuNotAllowed: 'You cannot purchase this product.',
      AddingSkuNotCompatible: 'This product cannot be shipped together with other products in your cart.',
      AddingSkuNotSellable: 'The product you are trying to add is not saleable.', // da revisionare
      CouponAlreadyUsedException: 'Coupon already used',
      CouponCartRangeException: 'Coupon is not applicable to this shopping cart',
      CouponInvalidException: 'The code entered does not correspond to any coupon or is not applicable to this cart',
      CouponLoggedGuestException: 'Coupon can only be used by a logged-in user',
      CustomerCannotSetFeedbackException: 'We found no orders among your purchases containing this product',
      CustomerEmailAlreadyExistException: 'Email already exists',
      CustomerEmailNotValid: 'Invalid email entered',
      CustomerEmailNotVerifiedException:
        'In order to continue, you must confirm your registration using the link you can find within the email you received after registration.',
      CustomerNotFoundException:
        'The address you entered does not appear within our systems, please check and try again.',
      CustomerUsernameAlreadyExistException: 'Username already exist',
      DataReferenceException: 'Internal error',
      DepositPaymentCardNoRemainingCreditException: 'No remaining credit',
      DepositPaymentCardNotFoundException: 'Code not recognized',
      DepositPaymentCardNotPermittedOnCartException: 'You can not use the gift card to buy another one',
      EJBException: 'Internal error',
      LoginException: 'Invalid username or password',
      LoginUserNotFoundException: 'Username not found',
      SystemException: 'Internal error',
      TaxCodeCheckDigitException:
        'The tax code you entered is invalid, please recheck the value you entered and try again.',
      TaxCodeLenghtException:
        'The tax code you entered is invalid, please recheck the value you entered and try again.',
      VatNumberCheckDigitException:
        'The vat number you entered is invalid, please recheck the value you entered and try again.',
      VatNumberLenghtException:
        'The vat number you entered is invalid, please recheck the value you entered and try again.',
      title: 'Error',
      usernotlogged: 'session expired',
    },
  },
  assembledProduct: {
    addToAssembly: 'Add to assembly', // da revisionare
    addToCart: 'Add to cart', // da revisionare
    completeTheAssembly: 'Complete the assembly', // da revisionare
    discountUpTo: 'Discount up to {{percentage}}%', // da revisionare
    discountUpToMini: 'Up to {{percentage}}%', // da revisionare
    freeShipping: 'Free shipping', // da revisionare
    removeFromAssembly: 'Remove product', // da revisionare
    summary: {
      actions: {
        addToCart: 'Add to cart', // da revisionare
        next: 'Next', // da revisionare
        previous: 'Previous', // da revisionare
      },
      badges: {
        freeShipping: 'Free shipping', // da revisionare
        totalDiscount: 'You are saving {{value}}', // da revisionare
      },
      infoMessage: {
        addAtLeast: '',
        addAtLeast_one: 'Add at least one product', // da revisionare
        addAtLeast_other: 'Add at least {{count}} products', // da revisionare
        addMoreProducts: '',
        addMoreProducts_one: 'Add another product to continue', // da revisionare
        addMoreProducts_other: 'Add more {{count}} products to continue', // da revisionare
        completeAllStepToContinue: 'To continue you must complete all step', // da revisionare
        completeStepToContinue: 'To continue you must complete the step', // da revisionare
      },
      status: '{{productAdded}}/{{maxProduct}} products added', // da revisionare
      subtitle: 'Assembly summary', // da revisionare
      title: 'Your total', // da revisionare
      total: 'Total', // da revisionare
      yourAssembly: 'Your assembly', // da revisionare
    },
    totalPieces: '{{total}} pieces', // da revisionare
  },
  bankAccountDetails: {
    bankName: 'Bank',
    bicSwiftCode: 'BIC/SWIFT',
    ibanCode: 'IBAN',
    ownerName: 'Payee',
  },
  cart: {
    badges: {
      discountValue: 'You are saving {{value}}', // da revisionare
      freeShipping: 'Free shipping', // da revisionare
    },
    cartCampaign: {
      toFreeSample: '<strong>{{toFreeSampleAmount}}</strong> are missing for free sample',
    },
    empty: {
      description: 'Search',
      title: 'Empty cart',
    },
    loyaltyPointsEarned: 'Your shopping cart can earn you <strong>{{loyaltyPointsEarned}} loyalty points</strong>',
    modal: {
      shippingCountry: {
        alert: 'If you choose to continue, the cart will be emptied and you will have to add products again.',
        title: 'Do you want to change the shipping country?',
      },
    },
    paymentMethods: {
      title: 'Payment methods',
    },
    productTile: {
      composedOf: 'Composed of:',
      removeItem: 'Remove item',
      sendToWishlist: 'Move to wishlist',
    },
    proocedToCart: 'Go to cart',
    quantity: 'Quantity',
    summary: {
      additional: {
        cashOnDelivery: 'Cash on delivery',
        title: 'Additional',
      },
      continueShopping: 'continue shopping',
      coupons: {
        removeCoupon: 'Remove',
        title: 'Insert discount code (optional)',
      },
      freeShipping: 'Free',
      freeShippingFrom: 'Free shipping from {{freeShippingFromAmount}}',
      giftCard: 'Gift card',
      proocedToCheckout: 'Checkout',
      shippingCountry: {
        edit: 'Edit',
        title: 'Shipping country',
      },
      shippingProfiles: {
        title: 'Shipment',
      },
      subtotal: 'Subtotal',
      title: 'Your order',
      toFreeShipping: '<strong>{{toFreeShippingAmount}}</strong> are missing for free shipping',
      toFreeShippingComplete: '<b>Free shipping</b> has been reached',
      totalBillingCost: 'Total (VAT included)',
      totalCost: 'Total to pay',
      totalDiscount: 'Discounts',
      totalProductsCost: 'Total products',
    },
    title: 'My cart',
  },
  checkout: {
    backToCart: 'Back to cart',
    billing: {
      addNewAddress: 'Add new address',
    },
    cancel: 'Cancel',
    cannotStartPayment: 'There are some problems in your cart, please check it again and then proceed',
    edit: 'Edit',
    invoice: {
      notRequested: 'You have not requested an invoice',
      requested: 'You requested the invoice',
    },
    shipping: {
      addNewAddress: 'Add new address',
    },
    step: {
      billingAddress: {
        subTitle: 'Same address',
        title: 'Billing address',
      },
      email: {
        alert: {
          buttons: {
            label: '',
          },
          title: '',
        },
        insertCredentials: 'Enter your credentials and do the access',
        login: 'Login',
        orderAs: 'You are ordering as',
        subTitle1: 'Complete your order faster',
        subTitle2: ' Log in with your account',
        title: 'Email',
      },
      paymentMethod: {
        subTitle: 'Choose payment method',
        title: 'Payment method',
      },
      shippingAddress: {
        title: 'Delivery address',
      },
    },
    summary: {
      additional: {
        cashOnDelivery: 'Cash on delivery',
        title: 'Additional',
      },
      cartItem: {
        edit: 'Edit',
        title: 'You are purchaising',
      },
      coupon: {
        add: 'Add',
        edit: 'Edit',
        title: 'Coupon',
      },
      giftCard: {
        add: 'Add',
        edit: 'Edit',
        title: 'Gift card',
      },
      shipping: {
        title: 'Shipment',
      },
      shippingCountry: {
        title: 'Shipping country',
      },
      subtotal: 'Subtotal',
      title: 'Your order',
      totalBillingCost: 'Total (VAT included)',
      totalCost: 'Total to pay',
      totalProductsCost: 'Total products',
    },
    thankYou: {
      cashOnDelivery: {
        amountToPayOnDelivery: 'Amount to pay on delivery',
      },
    },
  },
  contactUs: {
    customerOrder: {
      label: 'What information do you need?',
      placeholder: 'Select an option',
    },
    notSatisfied: {
      label: "Didn't find the answer you were looking for? Contact us!",
    },
    satisfied: {
      label: 'Did you find the answer you were looking for?',
      no: 'no',
      yes: 'yes',
    },
    subject: {
      label: 'Which order do you need help with?',
      placeholder: 'Select you order',
    },
  },
  customerCare: {
    contact: 'Contact us',
    help: 'Need help?',
    select: {
      label: 'Which information do you need?',
      optionLabel: 'Select an option',
    },
    service: 'Go to customer service area for quick help',
  },
  error: {
    pageNotFound: 'Ops. Page not found',
  },
  fixedPagesName: {
    account: 'account',
    cart: 'cart',
    'change-password': 'change-password',
    checkout: 'checkout',
    error: 'error',
    'forgot-password': 'forgot-password',
    home: 'home',
    login: 'login',
    newsletter: 'newsletter',
    'order-ko': 'order-ko',
    'order-ok': 'order-ok',
    register: 'register',
    search: 'search',
    sitemap: 'sitemap',
    template: 'template',
    test: 'test',
    'unsubscribe-newsletter': 'unsubscribe-newsletter',
    whois: 'whois',
  },
  footer: {
    paymentsMethod: 'Payment methods',
  },
  form: {
    addToCartForm: {
      buttons: {
        availabilityEmailNotification: {
          addLong: { label: 'Notify me' },
          addShort: { label: 'Notify me' },
          remove: { label: 'Not notify me' },
        },
        cancel: { label: 'Cancel' },
        reset: { label: 'Reset' },
        submit: { label: 'Purchase' },
        submitButNotAvailable: { label: 'Not available' },
      },
      fields: {
        giftCard: {
          message: {
            label: 'Message',
            placeholder: '',
          },
          receiverEmail: {
            label: "Recipient's email",
            placeholder: '',
          },
          receiverName: {
            label: 'Name of the recipient',
            placeholder: '',
          },
          senderName: {
            label: 'Your name',
            placeholder: '',
          },
          value: {
            placeholder: 'Select an option',
          },
        },
        quantity: {
          label: 'Quantity',
          placeholder: 'Select one of the options',
        },
        skuAttributes: {
          Gift_card_valore: {
            placeholder: 'Select the value',
          },
        },
        skus: {
          label: 'Skus',
          placeholder: 'Select a sku',
        },
      },
      misc: {
        productVariants: 'Product variants',
      },
      modal: {
        title: '',
      },
      outcome: {
        failure: {
          AddingSkuNotCompatible: {
            body: 'This product cannot be shipped together with other products in your cart. Do you want to proceed by emptying the current cart?', // da revisionare
            buttons: {
              no: { label: 'No' },
              yes: { label: 'Yes' },
            },
            title: 'We are sorry',
          },
        },
        success: {
          body: 'Added to cart',
          buttons: {
            continueShopping: { label: 'Continue shopping' },
            goToCart: { label: 'Go to cart' },
          },
          title: 'Added to cart',
        },
      },
    } satisfies Form,
    addressForm: {
      account: {
        defaultBilling: {
          label: 'Set as default payment address',
        },
        defaultShipping: {
          label: 'Set as default delivery address',
        },
      },
      address: {
        addressLine1: {
          label: 'Address',
          placeholder: '',
        },
        addressLine2: {
          label: 'Flat, floor ...',
          placeholder: '',
        },
        addressLine3: {
          label: '',
          placeholder: '',
        },
        alternativePhone: {
          label: 'Alternative phone',
          placeholder: '',
        },
        city: {
          label: 'City',
          placeholder: '',
        },
        civicNumber: {
          label: 'Civic',
          placeholder: '',
        },
        country: {
          label: 'Country',
          placeholder: '',
        },
        latitude: {
          label: 'Latitude',
          placeholder: '',
        },
        longitude: {
          label: 'Longitude',
          placeholder: '',
        },
        middleName: {
          label: 'Middle name',
          placeholder: '',
        },
        name: {
          label: 'Name',
          placeholder: '',
        },
        phone: {
          label: 'Phone',
          placeholder: '',
        },
        province: {
          label: 'Province',
          placeholder: 'Province',
        },
        region: {
          label: 'Region',
          placeholder: '',
        },
        shippingToAlert: 'You are shipping to {{locale}}, go back to change shipping country.',
        surname: {
          label: 'Surname',
          placeholder: '',
        },
        zipCode: {
          label: 'Zip code',
          placeholder: '',
        },
      },
      buttons: {
        cancel: {
          label: 'Cancel',
        },
        edit: {
          label: 'Edit',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Add',
        },
      },
      copyFromBilling: {
        label: 'Same address payment',
      },
      copyFromShipping: {
        label: 'Same delivery address',
      },
      copyToBilling: {
        label: 'Use also as payment address',
      },
      copyToShipping: {
        label: 'Use also as delivery address',
      },
      editAddressTitle: 'Edit address',
      invoice: {
        data: {
          company: {
            label: 'Company name',
            placeholder: '',
          },
          pec: {
            label: 'CEM',
            placeholder: '',
          },
          taxCode: {
            label: 'Tax code',
            placeholder: '',
          },
          uniqueCode: {
            label: 'Recipient code (e-invoice)',
            placeholder: '',
          },
          vatNumber: {
            label: 'Vat number',
            placeholder: '',
          },
        },
        request: {
          label: 'Need invoice?',
        },
        type: {
          label: 'Customer type',
          options: {
            company: 'Company',
            freelance: 'Individual company/Freelancer',
            privatePerson: 'Private',
          },
        },
      },
      modal: {
        title: '',
      },
      newAddressTitle: 'Insert new address',
    },
    cartAddressFromAddressBookForm: {
      buttons: {
        cancel: { label: 'Cancel' },
        editAddress: { label: 'Edit address' },
        newAddress: { label: 'Add new address' },
        reset: { label: 'Reset' },
        submit: { label: 'Confirm' },
      },
    } satisfies Form,
    cartEmailForm: {
      buttons: {
        cancel: {
          label: 'Cancel',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          checkout: {
            label: 'Edit mail',
          },
          whoIs: {
            label: 'Checkout',
          },
        },
      },
      fields: {
        email: {
          label: 'Email',
          placeholder: 'Email',
        },
        newsletterConsent: {
          label: 'Newsletter consent',
          radios: {
            false: 'No',
            true: 'Yes',
          },
        },
        privacy: {
          label: 'I consent to the processing of my personal data',
        },
      },
      handlers: {
        registeredEmail: {
          body: 'The email address you entered appears to be associated with an existing account, is that you?\n\nEnter your password to complete the login and associate the order with your account.', // da revisionare
          buttons: {
            cancel: {
              label: 'No, thank you', // da revisionare
            },
            submit: {
              label: 'Login', // da revisionare
            },
          },
          title: 'Do we know each other?', // da revisionare
        },
      },
      modal: {
        title: '',
      },
    } satisfies Form,
    cartGenericCouponForm: {
      buttons: {
        cancel: {
          label: 'Cancel',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Apply',
        },
      },
      fields: {
        code: {
          label: '',
          placeholder: 'Insert code',
        },
      },
      modal: {
        title: '',
      },
    } satisfies Form,
    cartPaymentMethodsForm: {
      buttons: {
        cancel: {
          label: 'Cancel',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Confirm',
        },
      },
      modal: {
        title: '',
      },
    } satisfies Form,
    cartPersonalCouponsForm: {
      misc: {
        noCoupon: '',
      },
      modal: {
        title: '',
      },
    } satisfies Form,
    changePasswordByTokenForm: {
      buttons: {
        cancel: {
          label: 'Cancel',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Submit',
        },
      },
      fields: {
        confirmNewPassword: {
          label: 'Confirm new password',
          placeholder: 'Confirm new password',
        },
        newPassword: {
          label: 'New password',
          placeholder: 'New password',
        },
      },
      modal: {
        title: '',
      },
      outcome: {
        success: {
          body: 'The password change procedure was completed successfully',
          title: 'Password change confirm',
        },
      },
    } satisfies Form,
    changePasswordForm: {
      buttons: {
        cancel: {
          label: 'Cancel',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Submit',
        },
      },
      fields: {
        confirmNewPassword: {
          label: 'Confirm new password',
          placeholder: 'Confirm new password',
        },
        currentPassword: {
          label: 'Current password',
          placeholder: 'Current password',
        },
        newPassword: {
          label: 'New password',
          placeholder: 'New password',
        },
      },
      modal: {
        title: 'Change password',
      },
      outcome: {
        success: {
          body: 'The password change procedure was completed successfully',
          title: 'Password change confirm',
        },
      },
    } satisfies Form,
    consentsForm: {
      buttons: {
        cancel: {
          label: 'Cancel',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Save',
        },
      },
      fields: {
        marketing: {
          label: 'Marketing consent',
          radios: {
            false: 'No',
            true: 'Yes',
          },
        },
        newsletter: {
          label: 'Newsletter consent',
          radios: {
            false: 'No',
            true: 'Yes',
          },
        },
        profiling: {
          label: 'Profiling consent',
          radios: {
            false: 'No',
            true: 'Yes',
          },
        },
      },
      modal: {
        title: '',
      },
    } satisfies Form,
    customerCareForm: {
      buttons: {
        cancel: {
          label: 'Cancel',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Send',
        },
      },
      fields: {
        email: {
          label: 'Email',
          placeholder: 'Email',
        },
        message: {
          label: 'Message',
          placeholder: 'Message',
        },
        name: {
          label: 'Name',
          placeholder: 'Name',
        },
        phoneNumber: {
          label: 'Phone',
          placeholder: 'Phone',
        },
        privacy: {
          label: 'I declare that I have read and accepted the privacy policy',
          placeholder: '',
        },
        surname: {
          label: 'Surname',
          placeholder: 'Surname',
        },
      },
      modal: {
        title: '',
      },
      outcome: {
        success: {
          body: 'Message sent successfully',
          title: 'Send message',
        },
      },
    } satisfies Form,
    customerForm: {
      birthdate: {
        errors: {
          minAge: 'Enter a valid birth date',
          minAgeWithDate: '',
          minAgeWithDate_one: 'You must be at least {{count}} years old',
          minAgeWithDate_other: 'You must be at least {{count}} years old',
        },
        label: 'Birthdate',
      },
      businessType: {
        label: 'Business type',
        options: {
          company: 'Company',
          proprietorship: 'Individual company/Freelancer',
        },
        placeholder: 'Select an option',
      },
      buttons: {
        cancel: {
          label: 'Cancel',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          registrationLabel: 'Register',
          updateLabel: 'Update',
          updateRequiredFieldsLabel: 'Complete profile',
        },
      },
      children: {
        birthdate: {
          errors: {
            minAge: 'Enter a valid birth date',
            minAgeWithDate: '',
            minAgeWithDate_one: 'Must be at least {{count}} years old',
            minAgeWithDate_other: 'Must be at least {{count}} years old',
          },
          label: 'Birthdate',
          placeholder: 'Birthdate',
        },
        buttons: {
          add: {
            label: 'Add child',
          },
          remove: {
            label: 'Remove child',
          },
        },
        formGroup: {
          label: 'Children (min: {{min}}, max: {{max}}, current: {{current}})',
        },
        limitDisclaimer: 'You have reached the maximum allowable limit',
        name: {
          label: 'Name',
          placeholder: 'Name',
        },
        sex: {
          label: 'Gender',
          options: {
            female: 'Female',
            male: 'Male',
            other: 'I prefer not to specify it',
          },
          placeholder: 'Select an option',
        },
        surname: {
          label: 'Surname',
          placeholder: 'Surname',
        },
      },
      company: {
        label: 'Company name',
        placeholder: 'Company name',
      },
      document: {
        authorityDescription: {
          label: 'Authority description',
          placeholder: 'Authority description',
        },
        authorityType: {
          label: 'Authority type',
          options: {
            consulate: 'Consulate',
            financeOffice: 'Finance office',
            foreignState: 'Foreign state',
            motorization: 'Motorization',
            municipality: 'Municipality',
            other: 'Other',
            prefecture: 'Prefecture',
            unknown: 'Unknown',
          },
          placeholder: 'Authority type',
        },
        districtName: {
          label: 'District name',
          placeholder: 'District name',
        },
        districtType: {
          label: 'District type',
          placeholder: 'District type',
        },
        documentNumber: {
          label: 'Document number',
          placeholder: 'Document number',
        },
        documentType: {
          label: 'Document type',
          options: {
            drivingLicense: 'Driving license',
            identityCard: 'Identity card',
            passport: 'Passport',
          },
          placeholder: 'Document type',
        },
        label: 'Document',
        releaseDate: {
          label: 'Release date',
          placeholder: 'Release date',
        },
      },
      email: {
        label: 'Email',
        placeholder: 'Email',
      },
      enableBusinessData: {
        formGroup: {
          label: 'Would you like to add your business data?',
        },
        options: {
          no: 'No',
          yes: 'Yes',
        },
      },
      enableChildren: {
        formGroup: {
          label: 'Do you wish to add children?',
        },
        options: {
          no: 'No',
          yes: 'Yes',
        },
      },
      enableDocument: {
        formGroup: {
          label: 'Do you wish to add a document?',
        },
        options: {
          no: 'No',
          yes: 'Yes',
        },
      },
      marketingConsent: {
        label: 'Marketing',
      },
      modal: {
        title: 'Personal data',
      },
      name: {
        label: 'Name',
        placeholder: 'Name',
      },
      newsletterConsent: {
        label: 'Newsletter',
      },
      password: {
        label: 'Password',
        placeholder: 'Password',
      },
      pec: {
        label: 'CEM',
        placeholder: 'CEM',
      },
      phoneNumbers: {
        buttons: {
          add: {
            label: 'Add number',
          },
          remove: {
            label: 'Remove number',
          },
        },
        label: 'Telephone number',
        placeholder: 'Telephone number',
      },
      privacyConsent: {
        label: 'Privacy',
      },
      profile: {
        label: 'Profile',
        options: {
          business: 'Business',
          private: 'Private',
        },
        placeholder: 'Select an option',
      },
      profilingConsent: {
        label: 'Profiling',
      },
      sex: {
        label: 'Gender',
        options: {
          female: 'Woman',
          male: 'Man',
          other: 'I prefer not to specify it',
        },
        placeholder: 'Select an option',
      },
      surname: {
        label: 'Surname',
        placeholder: 'Surname',
      },
      taxCode: {
        label: 'Tax code',
        placeholder: 'Tax code',
      },
      uniqueCode: {
        label: 'Recipient code (e-invoice)',
        placeholder: 'Recipient code (e-invoice)',
      },
      vatNumber: {
        label: 'Vat number',
        placeholder: 'Vat number',
      },
    },
    emailToCartForm: {
      buttons: {
        cancel: {
          label: 'Cancel',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Checkout',
        },
      },
      modal: {
        title: '',
      },
    } satisfies Form,
    exampleForm: {
      modal: {
        title: '',
      },
    } satisfies Form,
    forgotPasswordForm: {
      buttons: {
        cancel: {
          label: 'Cancel',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Submit',
        },
      },
      fields: {
        email: {
          label: 'Email',
          placeholder: 'Email',
        },
      },
      modal: {
        title: '',
      },
      outcome: {
        success: {
          body: 'Operation completed successfully!\n\nWe have sent you an email with instructions to follow.',
          title: 'Reset password',
        },
      },
    } satisfies Form,
    giftCardForm: {
      buttons: {
        balanceCheck: {
          label: 'Balance check',
        },
        cancel: {
          label: 'Cancel',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Apply',
        },
      },
      fields: {
        code: {
          label: 'Gift card',
          placeholder: 'Insert code',
        },
      },
    } satisfies Form,
    localeForm: {
      buttons: {
        cancel: {
          label: 'Cancel',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Go',
        },
      },
      fields: {
        locale: {
          label: 'Language',
        },
        shippingCountry: {
          label: 'Country',
        },
      },
      modal: {
        title: '',
      },
    } satisfies Form,
    loginForm: {
      buttons: {
        cancel: {
          label: 'Cancel',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Log in',
        },
      },
      fields: {
        password: {
          label: 'Password',
          placeholder: 'Password',
        },
        username: {
          label: 'Email',
          placeholder: 'Email',
        },
      },
      modal: {
        title: '',
      },
    } satisfies Form,
    newsletterForm: {
      buttons: {
        cancel: {
          label: 'Cancel',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Sign up',
        },
      },
      fields: {
        email: {
          label: 'Email',
          placeholder: 'Insert your email',
        },
        name: {
          label: 'Name',
          placeholder: 'Insert your name',
        },
        newsletterConsent: {
          label: '',
        },
        surname: {
          label: 'Surname',
          placeholder: 'Insert your surname',
        },
      },
      modal: {
        title: '',
      },
      outcome: {
        success: {
          body: 'Thanks for subscribing to our newsletter',
          buttons: {
            ok: {
              label: 'Ok',
            },
          },
          title: 'You successfully signed up to our newsletter',
        },
      },
    } satisfies Form,
    orderGuestForm: {
      buttons: {
        cancel: {
          label: 'Cancel',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Search',
        },
      },
      fields: {
        email: {
          label: 'Email',
          placeholder: 'email',
        },
        orderCode: {
          label: 'Order code',
          placeholder: 'order code',
        },
      },
      modal: {
        title: '',
      },
    } satisfies Form,
    restartPaymentForm: {
      buttons: {
        cancel: {
          label: 'Cancel',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Pay now',
        },
      },
      fields: {
        checkbox: {
          label: '',
        },
      },
      modal: {
        title: '',
      },
    } satisfies Form,
    reviewForm: {
      buttons: {
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Write review',
        },
      },
      fields: {
        message: {
          label: 'Add a comment',
          placeholder: 'Write your review',
        },
        rating: {
          label: 'How much did you like <strong>{{productName}}</strong>?',
        },
      },
      modal: {
        title: 'Tell us what you think!',
      },
      outcome: {
        failure: {
          generic: {
            body: 'We found no orders among your purchases containing this product',
            title: 'We are sorry',
          },
        },
        success: {
          body: 'Your review will be published after being reviewed by our customer care.',
          title: 'Thank you for your feedback!',
        },
      },
    } satisfies Form,
    shopSearchForm: {
      buttons: {
        cancel: {
          label: 'Cancel',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Submit',
        },
      },
    } satisfies Form,
    simpleSearchForm: {
      fields: {
        text: {
          label: 'Search',
          placeholder: 'Search',
        },
      },
    } satisfies Form,
    startPaymentForm: {
      buttons: {
        cancel: {
          label: 'Cancel',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Purchase',
        },
      },
      fields: {
        checkbox: {
          label: '',
        },
      },
      modal: {
        title: '',
      },
    } satisfies Form,
    taxBenefitsForm: {
      buttons: {
        browseFile: 'Browse file',
        cancel: 'Cancel',
        reset: {
          label: 'Reset',
        },
        submit: 'Submit',
      },
      documentValidation: {
        approved: 'Document approved.',
        awating: 'Document under approval.',
        rejected: 'Document rejected. Upload a new valid document.',
      },
      documentation: {
        label: 'Download documentation',
      },
      taxCode: {
        label: 'Tax code',
        placeholder: 'Tax code',
      },
    },
    updateCustomerRequiredFieldsForm: {
      modal: {
        title: '',
      },
    } satisfies Form,
  },
  header: {
    cartSummary: '',
    cartSummary_one: 'Order summary ({{count}} item)',
    cartSummary_other: 'Order summary ({{count}} items)',
    freeShippingFrom: 'Free shipping in {{locale}} from {{from}}',
    sendTo: 'Send to {{country}} | {{locale}}',
  },
  i18nTest: 'This is a test from b2x-react',
  misc: {
    account: 'Account',
    addAddress: 'Add address',
    addToCart: 'Add to cart',
    amount: 'Amount',
    availabilityEmailNotification: 'Notify me',
    availabilityNotification: 'We will notify you when available',
    back: 'Back',
    backToHome: 'Back to home',
    bestPrice: {
      info: 'info',
      modal: {
        lowestPrice: 'Lowest price in the last 30 days',
        priceToday: 'How much does this item cost today',
        title: 'Item price history',
      },
      priceLabel: 'Last lowest price:',
    },
    categories: 'Categories',
    changeCountryMessage: 'Warning. Changing country your cart will be emptied',
    checkout: 'Checkout',
    code: 'Code',
    customerForm: {
      registrationSuccessful: {
        modal: {
          body: 'Thanks for your subscription', // da revisionare
          title: 'Registration successful', // da revisionare
        },
      },
    },
    customerService: 'Customer service',
    directions: 'Directions',
    discoverMore: 'discover more',
    discoverOurCollection: 'Discover our collection',
    discoverOurProducts: 'Discover our products',
    doubleOptIn: {
      body: 'Your email address has been successfully validated, please login to start browsing.',
      title: 'Account verified',
    },
    edit: 'Edit',
    fileInput: {
      label: 'Choose file',
      placeholder: 'No file chosen',
      uploading: 'uploading...',
    },
    filter: 'Filter',
    filterAndOrderBy: 'Filter and order by',
    filterBy: 'Filter by',
    filterYourSearchResults: 'Filter your search results',
    followUs: 'Follow us',
    fromPrice: 'from {{value}}',
    giftCard: {
      balance: {
        activation: 'Activation',
        expiration: 'Expiration',
        title: 'Gift card details',
        value: 'Credit',
      },
      label: 'Gift card',
      message: 'Show message',
      receiver: {
        label: 'To:',
        mail: 'Email:',
      },
      sender: {
        label: 'From:',
        mail: 'Email:',
      },
      value: 'Worth:',
    },
    giftProduct: 'Gift product',
    gratis: 'Gratis',
    invalid_form_values: 'Verify all fields before you can continue',
    invoice: {
      company: 'Company name',
      pec: 'CEM',
      taxCode: 'Tax code',
      uniqueCode: 'Recipient code (e-invoice)',
      vatNumber: 'Vat number',
    },
    locale: {
      box: {
        title: {
          country: 'Select country and language',
          language: 'Select language',
        },
      },
    },
    loginRegister: 'Sign in/Sign up',
    myOrders: 'My orders',
    newProductSticker: 'New',
    newsletterTitle: 'Newsletter',
    or: 'or',
    orderBy: 'Order by',
    orderCode: 'Order code',
    passwordInput: {
      hide: 'hide',
      show: 'show',
    },
    payPalCheckout: {
      advanced: {
        privacyNotice:
          'By paying with your card, you acknowledge that your data will be processed by PayPal subject to the PayPal Privacy Statement available at <a href="https://www.paypal.com">PayPal.com</a>.', // da revisionare
      },
      capture: {
        errorModal: {
          body: 'Payment was not successful.\n\nThe circuit refused the payment, no charge was made.\n\nWe recommend that you check with your bank to find out the reason for the refusal.', // da revisionare
          title: 'Payment error', // da revisionare
        },
      },
      form: {
        buttons: {
          cancel: 'Cancel',
          submit: 'Pay',
        },
        fields: {
          cvv: {
            placeholder: 'CVV', // da revisionare
          },
          expiry: {
            placeholder: 'MM / YY', // da revisionare
          },
          name: {
            placeholder: 'Cardholder Name (optional)', // da revisionare
          },
          number: {
            placeholder: 'Card number', // da revisionare
          },
        },
        state: {
          invalid: 'The payment form is invalid', // da revisionare
        },
      },
    },
    payments: 'Payments',
    preferences: 'You might be interested',
    price: '{{value}}',
    pricePerShape: '{{price}} for {{shape}}', // da revisionare
    pricePerUnit: '{{price}} for {{measurementUnit}}',
    productNotAvailable: 'Not available',
    productsPicker: {
      progress: '',
      progress_one: 'You have selected {{count}} product out of {{total}} total products.', // da revisionare
      progress_other: 'You have selected {{count}} products out of {{total}} total products.', // da revisionare
      select: 'Select',
      selected: 'Selected',
    },
    readMore: 'Read more',
    register: 'Register now',
    relatedProducts: 'Related product',
    removeFilters: 'Remove all',
    searchItemResult: '',
    searchItemResult_one: '{{count}} result',
    searchItemResult_other: '{{count}} results',
    shipment: 'Shipments and returns',
    showLess: 'Show less',
    showMore: 'Show more',
    sizeGuide: 'Size guide',
    storeName: '',
    time: {
      days: '',
      days_one: 'Day',
      days_other: 'Days',
      hours: '',
      hours_one: 'Hour',
      hours_other: 'Hours',
      minutes: '',
      minutes_one: 'Minute',
      minutes_other: 'Minutes',
      seconds: '',
      seconds_one: 'Second',
      seconds_other: 'Seconds',
    },
    unsubscribeNewsletterByToken: {
      pending: 'Unsubscribing from newsletter...',
      success: 'You have successfully unsubscribed from the newsletter!',
      title: 'Newsletter',
    },
    vat: '(VAT {{value}}%)',
    viewAll: 'View all',
    welcomeMessage: 'Hello {{name}}',
    wishlist: 'Wishlist',
    youSearchedFor: 'Your search:',
  },
  modal: {
    geolocation: {
      content: '{{storeName}} is about to ask you to access your location for better use of the service.',
      title: 'Geolocation {{storeName}}',
    },
  },
  paymentMethod: {
    ALIAS: 'Payment by Alias',
    ANTICIPATO_BONIFICO: 'Pre-payment bank transfer',
    ANTICIPATO_CC: 'Pre-payment Credit Card',
    B2X_DIRECT: '',
    BANCOMAT_PAY: 'BANCOMAT Pay',
    BANK_TRANSFER: 'Bank transfer',
    BNL: 'Credito Card on BNL',
    CALL_CENTER: 'Call center',
    CASH: 'CASH',
    CASH_ON_DELIVERY: 'Collect on delivery',
    CMCIC: '',
    EASYPAY_CC: '',
    EASYPAY_MB: '',
    EASYPAY_MBWAY: '',
    ECPAY: '',
    FATTURA_30: 'Invoice 30 days',
    FATTURA_60: 'Invoice 60 days',
    FIDELITY_CARD: '',
    GESTPAY: '',
    GESTPAY_MYBANK: '',
    GIFT_CARD: 'Gift card',
    KEYCLIENT: 'Credit card',
    KLARNA: '',
    MULTIBANCO: '',
    MYBANK: '',
    NOT_NECESSARY_PAYMENT: 'Payment not required',
    NOT_SETTED_PAYMENT: 'Payment method not set',
    PAYPAL: 'PayPal',
    PAYPAL_V2: 'PayPal', // da revisionare
    PAY_ON_PHYSICAL_SHOP: '',
    POS: 'Credit card with POS on site',
    POSTE_BPIOL: 'Poste BPIOL',
    POSTE_BPOL: 'Poste BPOL',
    POSTE_CC: 'Credito Card on Poste',
    POSTE_PAY: 'PostePay',
    RIBA60: 'RiBa 60',
    RIBA_30: 'RiBa 30',
    RIMESSA_DIRETTA: 'Direct remittance',
    SATISPAY: 'Satispay',
    SCALAPAY: 'Scalapay payment',
    SETEFI: 'Credit Card',
    SETEFI_MASTERPASS: 'Masterpass',
    THIRD_PARTIES: 'Third-party',
    WANNME_BT: '',
    WANNME_CC: '',
    WANNME_PAY7: '',
    WANNME_RECURRENT: '',
    WORLDLINE_EASY_CHECKOUT: 'Debit/Credit card',
  },
  product: {
    caloriesPer100g: 'Calories per 100g',
    code: 'Sku',
    cookingMinutes: 'Minutes - cooking time',
    description: 'Description',
    details: 'Product details',
    dryingTime: 'Certified drying hours',
    features: 'Features',
    freeShippingFrom: 'Free shipping from {{freeShippingFromAmount}}',
    minutesOfCookingAlDente: 'Minutes - al dente',
    promo: 'Promo',
    relatedProduct: {
      title: 'You may also be interested in',
    },
    selectSize: 'Select size',
    share: 'share',
    size: 'Size:',
    sizeGuide: 'Size guide',
    temporary: {
      shipping: 'Fast delivery and 30 days easy returns',
      sizeGuide: 'Do not know your size?',
    },
    usageAndMaintenance: 'Usage and maintenance',
  },
  reviews: {
    buttons: {
      reviewForFirst: 'Review for first',
      showMore: 'Show more',
      signIn: 'Sign in',
      signUp: 'Sign up',
      writeReview: 'Write a review',
    },
    messages: {
      customer: 'Share your experience with this product.',
      guest: 'Sign in or register to leave a review.',
      withReviwes: 'Have you tried this product yet?',
      withoutReviews: 'Have you tried this product yet? Share your thoughts first and let us know what you think!',
    },
    misc: {
      emptyList: 'There are no reviews',
      filterBy: 'Filter by',
      review: '',
      review_one: '{{count}} Recensione',
      review_other: '{{count}} Recensioni',
      star: '',
      star_one: 'Star',
      star_other: 'Stars',
      writeReview: 'Write a review',
    },
    review: {
      notVerified: 'Review not verified',
      purchasedOn: 'Purchased: {{date}}',
      reviewedOn: 'Reviewed: {{date}}',
    },
    titles: {
      withReviwes: 'Reviews',
      withoutReviews: 'Write a review',
    },
  },
  sharingModal: {
    methods: {
      clipboard: {
        label: 'Copy link',
        success: 'Link copied to clipboard',
      },
      email: {
        label: 'Email',
      },
      facebook: {
        label: 'Facebook',
      },
      messenger: {
        label: 'Messenger',
      },
      whatsApp: {
        label: 'WhatsApp',
      },
      x: {
        label: 'X',
      },
    },
    title: 'Share',
  },
  sortingOption: {
    default: 'Relevance',
    id: {
      asc: '',
      desc: '',
    },
    name: {
      asc: '',
      desc: '',
    },
    price: {
      asc: 'Low price',
      desc: 'High price',
    },
    priority: {
      asc: '',
      desc: '',
    },
  },
  storeLocator: {
    filterPanel: {
      selectProduct: 'Select the product',
    },
    listingPanel: {
      loading: 'loading...',
      shopsCount: '',
      shopsCount_one: '{{count}} Punto Vendita',
      shopsCount_other: '{{count}} Punti Vendita',
    },
    modal: {
      desktopPhone: {
        content: 'For more information about {{name}}, contact the number {{phoneNumber}}',
        title: 'Contact the Store',
      },
      gallery: {
        title: 'Store photo',
      },
      geolocationError: {
        content: 'Geolocation denied',
        title: 'Unable to center map, geolocation denied',
      },
    },
    shopDetails: {
      closed: 'Closed',
      cta: {
        call: 'Call',
        directions: 'Directions',
      },
      description: 'Description',
      openingDays: {
        FRIDAY: 'Friday',
        MONDAY: 'Monday',
        SATURDAY: 'Saturday',
        SUNDAY: 'Sunday',
        THURSDAY: 'Thursday',
        TUESDAY: 'Tuesday',
        WEDNESDAY: 'Wednesday',
      },
      socialNetwork: 'Social Network',
    },
    shopTile: {
      CLOSED: 'Closed',
      OPEN: 'Open',
      closesAtDayTime: 'Closes {{day}} at {{time}}',
      closesAtTime: 'Closes at {{time}}',
      copyLink: 'Copy link',
      linkCopied: 'Link copied to clipboard',
      opensAtDayTime: 'Apens {{day}} at {{time}}',
      opensAtTime: 'Apens at {{time}}',
      share: 'Share',
    },
  },
  survey: {
    buttons: {
      complete: {
        label: 'Discover the result',
      },
      nextQuestion: {
        label: 'Next',
      },
      prevQuestion: {
        label: 'Prev',
      },
      reset: {
        label: 'Reset',
      },
    },
  },
  topSuggestionsBox: {
    categories: 'Categories',
    popularSearches: 'Popular searches',
    recentSearches: 'Recent searches',
    showAllProducts: 'Show all products',
  },
  updateCustomerRequiredFieldsPage: {
    subtitle: 'Complete your data and begin to shop',
    title: 'Hi',
    titleWithName: 'Hi {{name}}',
  },
  wishlist: {
    modal: {
      availabilityNotificationDisabled: 'You will no longer be notified when the product becomes available again.',
      availabilityNotificationEnabled: 'You will be notified as soon as the product becomes available again.',
      buttons: {
        continueShopping: {
          label: 'Continue shopping',
        },
        goToWishlist: {
          label: 'Go to wishlist',
        },
      },
      productAdded: 'The selected product has been added to the wishlist.',
      productRemoved: 'The selected product has been removed from the wishlist.',
      title: 'Wishlist update',
    },
  },
  yup: {
    array: {
      length: '{{path}} must have {{length}} items',
      max: '{{path}} field must have less than or equal to {{max}} items',
      min: '{{path}} field must have at least {{min}} items',
    },
    boolean: {
      isValue: '{{path}} field must be {{value}}',
    },
    custom: {
      passwordConfirmationNotMatch: 'The password confirmation does not match',
    },
    date: {
      max: '{{path}} field must be at earlier than {{max}}',
      min: '{{path}} field must be later than {{min}}',
    },
    mixed: {
      default: '{{path}} is invalid',
      defined: '{{path}} must be defined',
      notOneOf: '{{path}} must not be one of the following values: {{values}}',
      notType: '{{path}} must be a `{{type}}` type, but the final value was: {{value}}',
      oneOf: '{{path}} must be one of the following values: {{values}}',
      required: 'Required field',
    },
    number: {
      integer: '{{path}} must be an integer',
      lessThan: '{{path}} must be less than {{less}}',
      max: '{{path}} must be less than or equal to {{max}}',
      min: '{{path}} must be greater than or equal to {{min}}',
      moreThan: '{{path}} must be greater than {{more}}',
      negative: '{{path}} must be a negative number',
      positive: '{{path}} must be a positive number',
    },
    object: {
      noUnknown: '{{path}} field has unspecified keys: {{unknown}}',
    },
    string: {
      email: '{{path}} must be a valid email',
      length: '{{path}} must be exactly {{length}} characters',
      lowercase: '{{path}} must be a lowercase string',
      matches: '{{path}} must match the following: "{{regex}}"',
      max: '{{path}} must be at most {{max}} characters',
      min: '{{path}} must be at least {{min}} characters',
      trim: '{{path}} must be a trimmed string',
      uppercase: '{{path}} must be a upper case string',
      url: '{{path}} must be a valid URL',
      uuid: '{{path}} must be a valid UUID',
    },
  },
};

interface FormField {
  errors?: Record<string, string>;
  label?: string;
  listButtons?: {
    add: Button;
    remove: Button;
  };
  options?: Record<string, string>;
  placeholder?: string;
  radios?: Record<string, string>;
}

interface Button {
  label?: string;
}

interface Nested<T> {
  [key: string]: T | Nested<T>;
}

interface FormModal {
  body?: string;
  buttons?: Record<string, Button>;
  title?: string;
}

interface Form {
  buttons?: Nested<Button>;
  fields?: Nested<FormField>;
  handlers?: Record<string, FormModal>;
  misc?: Nested<string>;
  modal?: FormModal;
  outcome?: {
    failure?: Record<string, FormModal>;
    success?: FormModal;
  };
}
