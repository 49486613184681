import { ProductApiDto } from '@b2x/storefront-api-js-client/src';
import classnames from 'classnames';
import React from 'react';

import { b2x } from '../../..';
import { AddToCartFormButton } from '../../../AddToCartFormButton';
import { Col } from '../../../Col';
import { appConfig } from '../../../config';
import { AddToCartFormHelper } from '../../../form/AddToCartForm';
import { Select } from '../../../form/fields/Select';
import { FormGroup } from '../../../form/FormGroup';
import { Div, P } from '../../../HTMLElement';
import { t } from '../../../i18n/i18n';
import { Image } from '../../../Image';
import { Listing } from '../../../Listing';
import { PriceBlock } from '../../../PriceBlock';
import { Row } from '../../../Row';
import { useSearch } from '../../../useSearch';
import { renderUI } from '../../../util';
import { AccountQuickReorderSubpageProps } from '../AccountQuickReorderSubpage';
import { AccountSubpage } from '../AccountSubpage';
import { Box } from './Box';

export const AccountQuickReorderSubpageA = (props: AccountQuickReorderSubpageProps) => {
  const { searchResult } = useSearch({
    basePath: window.location.pathname,
    defaultPageSize: 10,
    populate: {
      items: {
        skus: {
          price: true,
        },
        variantProducts: {
          priceRange: true,
          skus: {
            price: true,
            product: true,
          },
        },
      },
    },
    withOrderedProducts: true,
  });

  return renderUI({
    bs5: (
      <AccountSubpage>
        <Box>
          <div className="quick-reorder">
            {searchResult && searchResult.itemsCount > 0 ? (
              <React.Fragment>
                <P className="products-counter">
                  {t('account.quickReorder.productCount', { count: searchResult.itemsCount })}
                </P>
                <Div className="quick-reorder" marginBottom={3}>
                  <Div className="quick-reorder-heading" display={{ md: 'block', xs: 'none' }}>
                    <Row gap={0}>
                      <Col size={6}>
                        <Div paddingX={3} paddingY={2}>
                          {t('account.quickReorder.table.product')}
                        </Div>
                      </Col>
                      <Col size={6}>
                        <Div paddingX={3} paddingY={2}>
                          {t('account.quickReorder.table.quantity')}
                        </Div>
                      </Col>
                    </Row>
                  </Div>
                  <Div className="product-list">
                    {searchResult.items && (
                      <Listing name="Quick reorder" products={searchResult.items}>
                        {searchResult.items.map((item) => (
                          <React.Fragment key={item.id}>
                            <QuickOrderItem isMainProduct preselectedSkuId={item.skus?.at(0)?.id} product={item} />
                            {item.variantProducts?.map((variant) => (
                              <QuickOrderItem
                                key={variant.id}
                                preselectedSkuId={variant.skus?.at(0)?.id}
                                product={variant}
                              />
                            ))}
                          </React.Fragment>
                        ))}
                      </Listing>
                    )}
                  </Div>
                </Div>
                <b2x.Div display="flex" justifyContent={{ md: 'end', xs: 'center' }}>
                  <b2x.Pagination
                    currentPage={searchResult.pageNumber}
                    pageOffset={2}
                    showDots
                    singleStepNavigation
                    totalPages={searchResult.pagesCount}
                  />
                </b2x.Div>
              </React.Fragment>
            ) : (
              <P className="no-products">{t('account.quickReorder.noProducts')}</P>
            )}
          </div>
        </Box>
      </AccountSubpage>
    ),
  });
};

interface QuickOrderItemProps {
  isMainProduct?: boolean;
  preselectedSkuId?: string;
  product: ProductApiDto;
}

const QuickOrderItem = ({ isMainProduct, preselectedSkuId, product }: QuickOrderItemProps) => {
  const currentSku = product.skus?.at(0);

  return (
    <Div className={classnames({ main: isMainProduct }, 'product')} key={product.id}>
      <AddToCartFormHelper preselectedSkuId={preselectedSkuId} product={product} scope="tile">
        {({ fieldsHelper, image, priceHelper, selectedSku }) => (
          <Row alignItems={isMainProduct ? undefined : 'center'} gap={0}>
            {!isMainProduct && (
              <Col className="separator-col" display={'flex'} justifyContent={'end'} size={12}>
                <Div className="separator" />
              </Col>
            )}
            <Col size={{ md: 6, xs: 12 }}>
              <Row gap={0}>
                <Col className="image-col" size={'auto'}>
                  <Div padding={3}>
                    {isMainProduct && (
                      <b2x.router.Link to={product.fullPath}>
                        <Image {...image} aspectRatio={appConfig.productImageAspectRatio} fluid />
                      </b2x.router.Link>
                    )}
                  </Div>
                </Col>
                <Col className="product-info-col">
                  <Div padding={3} paddingStart={{ md: 0, xs: isMainProduct ? 0 : 3 }}>
                    {isMainProduct && (
                      <P className="product-title">
                        <b2x.router.Link to={product.fullPath}>{product.name} </b2x.router.Link>
                      </P>
                    )}
                    <P className="sku-title">{currentSku?.name}</P>
                    <PriceBlock priceHelper={priceHelper} />
                  </Div>
                </Col>
              </Row>
            </Col>
            <Col size={{ md: 6, xs: 12 }}>
              <Div paddingBottom={3} paddingTop={{ md: 3, xs: 0 }}>
                <Row gap={0}>
                  {selectedSku?.state === 'AVAILABLE' && (
                    <Col className="quantity-col" size={'auto'}>
                      <Div paddingX={3}>
                        {/* <FormGroup {...fieldsHelper.productVariants.formGroup}>
                      {fieldsHelper.productVariants.formFields.map((formField) => (
                        <Radio key={formField.productVariant.id} {...formField.radio} />
                      ))}
                    </FormGroup>
                    <FormGroup {...fieldsHelper.skus.formGroup}>
                      {fieldsHelper.skus.formFields.map((formField) => (
                        <Radio key={formField.sku.id} {...formField.radio} />
                      ))}
                    </FormGroup> */}
                        <FormGroup {...fieldsHelper.quantity.formGroup} label={undefined} noMarginBottom>
                          <Select {...fieldsHelper.quantity.select} includeEmptyOption={false} />
                        </FormGroup>
                      </Div>
                    </Col>
                  )}
                  <Col
                    className={classnames('add-to-cart-col', {
                      'product-not-available': selectedSku?.state === 'NOT_AVAILABLE',
                    })}
                    display={'flex'}
                    justifyContent={'end'}
                  >
                    <Div paddingX={3}>
                      <AddToCartFormButton fieldsHelper={fieldsHelper} selectedSku={selectedSku} />
                      {/* <Button {...fieldsHelper.buttons.submit} /> */}
                    </Div>
                  </Col>
                </Row>
              </Div>
            </Col>
          </Row>
        )}
      </AddToCartFormHelper>
    </Div>
  );
};
