import React from 'react';

import { analytics } from './analytics/analytics';
import { useAppContext, useAppStaticContext } from './AppContext';
import { Fade } from './Fade';
import { useLocation } from './router/router';
import { structuredData } from './structuredData/structuredData';

export interface PageProps {
  children?: React.ReactNode;
  className?: string;
  id?: string;
  // loginRequired?: boolean;
  thingsToLoadBeforePageReady?: Array<unknown>;
}

export const Page = ({
  children,
  className,
  id,
  thingsToLoadBeforePageReady = [],
}: // loginRequired,
PageProps) => {
  const { pageReady, routeInfo, session } = useAppContext();
  const { setPageReady } = useAppStaticContext();
  const location = useLocation();
  // const [accessGranted, setAccessGranted] = React.useState<boolean>(false);

  React.useEffect(() => {
    const everythingIsReady = thingsToLoadBeforePageReady.every((thing) => thing !== undefined);
    if (everythingIsReady) {
      setPageReady(true);
    } else {
      setPageReady(false);
    }
    if (location.pathname === '') {
      // è un if a caso, messo giusto per forzare la presenza di location nell'array di dipendenze.
      // Devo infatti far partire questo useEffect ogni volta che cambia la path (esempio: navigazione in sottopagine area personale)
    }
  }, [location.pathname, setPageReady, thingsToLoadBeforePageReady]);

  // const { session } = useAppContext();
  // const { showModal } = useModals();
  // const navigate = useNavigate();

  // // Se la pagina richiede la login ma non sono loggato, blocco.
  // React.useEffect(() => {
  //   if (loginRequired && !session?.customer) {
  //     showModal({
  //       buttons: [
  //         {
  //           close: true,
  //           label: 'Torna in Homepage',
  //           onClick: () => {
  //             navigate('/');
  //           },
  //         },
  //       ],
  //       children: 'Lorem Ipsum devi essere loggato',
  //       closable: false,
  //       title: 'lorem ipsum',
  //     });
  //   } else {
  //     setAccessGranted(true);
  //   }
  // }, [loginRequired, navigate, session?.customer, showModal]);

  // return <Fade show={pageReady}>{accessGranted && children}</Fade>;

  const pageViewEventFired = React.useRef<boolean>(false);
  React.useEffect(() => {
    if (pageViewEventFired.current === false) {
      if (routeInfo && session) {
        pageViewEventFired.current = true;
        analytics.events.pageView('EVENT_ID', routeInfo, session);
      }
    }
  }, [routeInfo, session]);

  React.useEffect(() => {
    return () => {
      analytics.events.pageLeave('EVENT_ID');
    };
  }, []);

  return (
    <Fade className={className} show={pageReady}>
      {routeInfo?.pathname === '/' && (
        <>
          <structuredData.WebSite />
          <structuredData.Organization />
        </>
      )}
      {children}
    </Fade>
  );
};
