import { env } from '../env';
import { addScript } from '../util';
import { AnalyticsEventsProps, InitCommonProps } from './analytics';

declare global {
  interface Window {
    // @typescript-eslint/no-explicit-any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ScarabQueue: Array<any>;
  }
}

let initialized = false;
let debug = false;

interface InitProps extends InitCommonProps {
  id?: string;
}

export type EmarsysWebExtendInitProps = InitProps;

const init = (props?: InitProps): void => {
  const id = props?.id ?? env.REACT_APP_EMARSYS_WEB_EXTEND_MERCHANT_ID;
  debug = props?.debug || false;
  if (id) {
    if (!initialized) {
      initialized = true;
      install(id);
    }
  }
};

const install = (merchantId: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  window.ScarabQueue = window.ScarabQueue || [];

  addScript({
    async: true,
    id: 'scarab-js-api',
    src: `//cdn.scarabresearch.com/js/${merchantId}/scarab-v2.js`,
  });
};

const log = (message?: unknown, ...optionalParams: Array<unknown>) => {
  if (debug) {
    console.log('# analytics', 'emarsysWebExtend:', message, ...optionalParams);
  }
};

type EventName = 'go' | 'setEmail' | 'view' | 'searchTerm' | 'category' | 'cart' | 'purchase' | 'tag';

export interface EventData {
  categories?: string;
  items?: Array<Item>;
  orderId?: string;
  price?: number;
  products?: string;
  quantity?: number;
  total_price?: number;
}
export interface Item {
  item?: string;
  price?: number;
  quantity?: number;
}

const sendEvent = (event: EventName, ...parameters: Array<EventData | Array<Item> | string | undefined>) => {
  if (initialized) {
    window.ScarabQueue.push([event, ...parameters]);
    log(event, ...parameters);
  } else {
    log('Blocked because not initialized.', event, ...parameters);
  }
};

export const emarsysWebExtend: {
  events: AnalyticsEventsProps;
  init(props?: InitProps): void;
} = {
  events: {
    addPaymentInfo: (props) => {
      // Assente in webExtend
    },
    addShippingInfo: (props) => {
      // Assente in webExtend
    },
    addToCart: (props) => {
      // Assente in webExtend
    },
    addToWishlist: (props) => {
      // Assente in webExtend
    },
    checkoutInit: (props) => {
      // Assente in webExtend
    },
    completeRegistration: (props) => {
      // Assente in webExtend
    },
    contact: (props) => {
      // Assente in webExtend
    },
    customizeProduct: (props) => {
      // Assente in webExtend
    },
    donate: (props) => {
      // Assente in webExtend
    },
    exception: (props) => {
      // Assente in webExtend
    },
    findLocation: (props) => {
      // Assente in webExtend
    },
    lead: (props) => {
      // Assente in webExtend
    },
    login: (props) => {
      // Assente in webExtend
    },
    pageLeave: (props) => {
      // One time event for each page (on page leave)
      sendEvent('go');
    },
    pageView: (props) => {
      const { routeInfo, session } = props;
      // Event for all pages
      if (session?.customer?.email && session.userLogged) {
        sendEvent('setEmail', session.customer.email);
      }

      // Event for all pages
      const products = Array<Item>();
      if (session?.cart && session.cart.packs?.length) {
        session.cart.packs.forEach((pack) => {
          pack.cartSkus?.forEach((cartSku) => {
            products.push({
              item: cartSku.sku?.code,
              price: cartSku.price?.value,
              quantity: cartSku.quantity,
            });
          });
        });
      }
      sendEvent('cart', products);

      // Per pagine specifiche
      switch (routeInfo?.type) {
        case 'listing':
          const categoriesPath = routeInfo.pathname.split('/').filter(Boolean).join(' > ');
          sendEvent('category', categoriesPath);
          break;
        default:
          break;
      }
    },
    purchase: (props) => {
      if (props.paymentMethod === 'BANK_TRANSFER' || props.paymentMethod === 'ANTICIPATO_BONIFICO') {
        const productsname = props.products.map((el) => el.name).join(',');
        sendEvent('tag', 'purchase_offline', {
          orderId: props.orderCode,
          products: productsname,
          total_price: props.totalCost,
        });
      } else {
        sendEvent('purchase', {
          items: props.products.map((item) => ({
            item: item.sku.code,
            price: item.sku.price.value,
            quantity: item.quantity,
          })),
          orderId: props.orderCode,
        });
      }
    },
    removeFromCart: (props) => {
      // Assente in webExtend
    },
    schedule: (props) => {
      // Assente in webExtend
    },
    search: (props) => {
      sendEvent('searchTerm', props.simpleSearch);
    },
    selectProduct: (props) => {
      // Assente in webExtend
    },
    startPayment: (props) => {
      // Assente in webExtend
    },
    submitApplication: (props) => {
      // Assente in webExtend
    },
    viewCart: (props) => {
      // Assente in webExtend
    },
    viewProduct: (props) => {
      sendEvent('view', props.product.skus[0].code ?? props.product.skus[0].id);
    },
    viewProducts: (props) => {
      // Assente in webExtend
    },
  },
  init: (props) => {
    init(props);
  },
};
